import React, { useEffect, useState } from 'react'
import logoReverse from '../../images/reverseLogo.png'
import logo from '../../images/logo.png'
import "./Header.scss"

const Header = () => {
    const [colorHeader, setColorHeader] = useState(false);
    const [iconHeader, setIconHeader] = useState(logoReverse);
    const [isOpened, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 20
            if (scrollCheck) {
                setColorHeader(true);
                setIconHeader(logo);
            } else {
                setColorHeader(false);
                setIconHeader(logoReverse);
            }
        })
    });

    const toggleNav = () => {
        setOpen(!isOpened);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top nav-black" id="mainNav" style={colorHeader ? {backgroundColor: "#FFFF"} : {backgroundColor: "#db3c62"}}>
                <div className="container">
                    <a className="navbar-brand" href='/'>
                        <img src={logo} style={{display: colorHeader ? "" : "none"}} alt="" />
                        <img src={logoReverse} style={{display: colorHeader ? "none" : ""}} alt="" />
                    </a>
                    <button className="navbar-toggler" style={colorHeader ? {color: '#db3c62', border: "1px solid #db3c62"} : {color: 'white', border: "1px solid white"}} onClick={toggleNav} type="button" data-bs-toggle="collapse" data-bs-target="#navBarContent" aria-controls="navBarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className={`${isOpened ? "" : "collapse "}navbar-collapse`}  id="navBarContent">
                        <div className='navbar-nav ml-auto order-0'>
                            <div className="nav-item">
                                <a className="nav-link" style={colorHeader ? {color: '#db3c62'} : {color: 'white'}} href='/about'>
                                    About
                                </a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" style={colorHeader ? {color: '#db3c62'} : {color: 'white'}} href='/privacy-policy'>
                                    Privacy Policy
                                </a>
                            </div>
                            <div className={colorHeader ? "nav-item-reverse" : "nav-item"}>
                                <a className="nav-link" style={colorHeader ? {color: '#db3c62'} : {color: 'white'}} href='mailto:support@tape-me.com'>
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header