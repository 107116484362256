import React from "react"
import "./Footer.scss"

const Footer = () => (
  <footer>
    <div className="container">
        <div className="row">
            <div className="col-md-auto">
            </div>
            <div className="col-md">
                <ul className="list-inline list-unstyled text-md-right socials">
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://www.reddit.com/r/TapeMe_official/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-reddit-square"></i>
                    </a>
                    </li>
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://www.instagram.com/tapeme.official/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                    </li>
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://www.facebook.com/Tape-Me-106060852169172/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-facebook-square"></i>
                    </a>
                    </li>
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://www.youtube.com/channel/UCZXg84OiT-aMilgcFDGkfYg"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-youtube-square"></i>
                    </a>
                    </li>
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://cz.pinterest.com/TapeMeofficial/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-pinterest-square"></i>
                    </a>
                    </li>
                    <li className="list-inline-item">
                    <a
                        className=""
                        href="https://www.tiktok.com/@tapeme.official"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-tiktok"></i>
                    </a>
                    </li>
                </ul>
                </div>
        </div>
        <div className="row">
            <div className="col footer-copyright">© 2022 coalsoft s.r.o.</div>
        </div>
    </div>
  </footer>
)

export default Footer
